/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&family=Montserrat:wght@400;500;600;700;800&family=Poppins:wght@400;500;700&display=swap');

:root {
    /* Colors */
    --background: #2E2E2E;
    --pink: #FF9AA5;
    --white: #FFFFFF;
    --lightest-gray: #F2F2F2;
    --lighter-gray: #C9C9C9;
    --text-gray: #C9C9C9;
    --light-gray: #8B8B8B;
    --medium-gray: #616161;
    --dark-gray: #1E1E1E;
    --card-background: #5A5A5A;
    --dark-mode-line: #404040;
    --light-mode-line: #DEDEDE;
    --tag-text: #707070;
    --label-background: #535353;
    --border-color: #FF9AA5;


    /*Typography*/
    --body-font: 'Montserrat', sans-serif;
    --socials-font: 'Inter', sans-serif;
    --fw-regular: 400;
    --fw-medium: 500;
    --fw-semi-bold: 600;
    --fw-bold: 700;
    --fw-extrabold: 800;

    /* theme values that need to change */
    --button-text: #ffffff;
}

* {
    padding:0;
    margin: 0;
    box-sizing: border-box;
}

html, body {
    width: 100%;
    overflow-y: auto;
    font-family: var(--body-font);
    background-color: var(--background); 
}

/* highlight color  */
::selection {
    background:var(--pink);
    color: white;
}

/* values for light theme */
[data-theme="light"] {
    --background: white;
    --white: #1e1e1e;
    --text-gray: #8b8b8b;
    --label-background: #ececec;
    --border-color: #dddddd;
}

/* Media Queries */

/* mobile phones */
@media only screen and (max-width:600px) {
   html, body {
    width: 100%;
    height: 100%;
   }
}
