/* mobile first */

.image-container {
    display: flex;
    justify-content: center;
    width: 20em;
    padding-top: 2em;
    padding-bottom: 2em;
}

.headshot {
    width: 100%;
    border-radius: 0.5em;
}

.column-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.column-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 2rem;
    padding-top: 1em;
    text-transform: uppercase;
    color: var(--white);
}


/* values for light mode
.about-light {
    font-size: 2rem;
    text-transform: uppercase;
    padding-top: 1em;
    color: #FF9AA5;
} */

.bio-paragraph-one, .bio-paragraph-two {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    line-height: 2.5rem;
    color: var(--light-gray);
    width: 25em;
    
    padding-top: 1em;
    padding-bottom: 1em;
}

.bio-paragraph-two {
    padding-bottom: 2em;
}

.details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    font-size: 1rem;

}


.name, .education {
    padding-bottom: 1em;
}

.detail-title {
    color: var(--pink);
    font-weight: bold;
    text-transform: uppercase;
}


.myName, .school, .degree, .status {
    font-family: 'Poppins', sans-serif;
    color: var(--text-gray);
    font-weight: var(--fw-medium);
}

.status {
    padding-bottom: 1.5em;
}

.skillset .skillset-title {
    color: var(--white);
}


.download-btn {
    display: block;
    color: var(--background);
    background-color: var(--pink);
    border-radius: 0.3125em;
    text-decoration: none;
    font-weight: var(--fw-bold);
    font-size: 14px;
    text-transform: uppercase;
    width: 150px;
    transition: transform 600ms;
    padding: 1em 1em;
    align-items: center;
    justify-content: center;
    text-align: center;

    &:hover {
        color: var(--medium-gray);
        background-color: var(--dark-gray);
        transform: translateY(-10px);
    }
}

.desktop-btn {
    display: none;
}

.computer-container {
    display: none;
}

.mobile-dropdown {
    padding-top: 2em;
}

.desktop-dropdown {
    display: none;
}


/* small mobile screens */
@media only screen and (min-width: 300px) and (max-width: 400px)  {

    .bio-paragraph-one, .bio-paragraph-two {
        width: 21.5em;
    }

    .dark-mode-container {
        display: none;
    }

    .bio-paragraph-two {
        padding-bottom: 2em;
    }

    .skillset {
        display: none;
    }

}

@media only screen and (min-width: 401px) and (max-width: 549px) {
    .dark-mode-container {
        display: none;
    }

    .bio-paragraph-two {
        padding-bottom: 2em;
    }

    .skillset {
        display: none;
    }
}


/* medium mobile screens */
@media only screen and (min-width: 550px) and (max-width: 768px){
    .bio-paragraph-one, .bio-paragraph-two {
        width: 35em;
        padding-left: 2em;
        padding-right: 2em;
    }

    .dark-mode-container {
        display: none;
    }

    .bio-paragraph-two {
        padding-bottom: 2em;
    }

    .skillset {
        display: none;
    }
}

/* ipad portrait */
@media only screen and (min-width: 769px) and (max-width: 1366px) {
    .bio-paragraph-one, .bio-paragraph-two {
        font-size: 20px;
        width: 45em;
        max-width: calc(100% - 20px);
    }

    .details {
        font-size: 20px;

    }

    .download-btn {
        font-size: 16px;
        width: 200px;
    }   

    .dark-mode-container {
        display: none;
    }

    .bio-paragraph-two {
        padding-bottom: 2em;
    }

    .skillset {
        display: none;
    }
}


/* medium desktop screens */
@media only screen and (min-width: 1367px) and (max-width: 1600px) {
    .main-content {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .column-left {
        padding-left: 5em;
        display: flex;
        flex-direction: column;
        justify-content:flex-start;
    }

    .column-right {
        /* margin-right: 5em; */
        justify-content: start;
        align-items: start;
        /* padding-left: 4em; */
    }

    .column-right .bio-paragraph-one, .bio-paragraph-two {
        width: 45em;
    }

    .column-right .bio-paragraph-two {
        width: 40em;
    }

    .skillset-title {
        text-transform: uppercase;
        font-size: 1.5rem;
        color: white;
        padding-top: 1em;
        padding-bottom: 1em;
    }

    
    .image-container {
        width: 20em;
        padding-bottom: 2em;
        
    }
    
    .headshot {
        width: 100%;
        border-radius: 0.5em;
    }
    
    .mobile-btn {
        display: none;
    }

    .desktop-btn {
        display:inline-flex;
        flex-direction: column;
    }
    
    
    .bio-paragraph-one, .bio-paragraph-two {
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: 2.5rem;
        color: var(--light-gray);
        width: 60em;

        text-align: unset;
    }
    
    .bio-paragraph-one {
        padding-bottom: 1em;
    }

    .bio-paragraph-two {
        padding-bottom: 2em;
    }


    .details {
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: 4.2em;

        align-items: start;
        text-align: left;
        font-size: 18px;
    }
    
    .name, .education, .employment {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
    }

    .name {
        padding-right: 4em;
    }
    
    .detail-title {
        color: #FF9AA5;
        font-weight: bold;
        text-transform: uppercase;
    }
    
    .myName, .school, .degree, .status {
        font-family: 'Poppins', sans-serif;
        color: var(--text-gray);
        font-weight: var(--fw-medium);
    }

    .employment {
        font-size: 18px;
    }

    .dropdown-container {
        display: none;
    }

    .desktop-dropdown {
        display: inline-flex;
        padding-top: 2em;
    }


    .computer-container{
        display: inline-flex;
        width: 100%;
    }

     
    .mobile-dropdown {
        display: none;
    }

    .dark-mode-container {
        position: absolute;
        bottom: 0;
        right: 0;
        margin-bottom: -12em;
        margin-right: 2em;
    }

    
}

/* large desktop screens */
@media screen and (min-width: 1601px) and (max-width: 4000px){
    .main-content {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .column-left {
        padding-left: 2em;
        display: flex;
        flex-direction: column;
        justify-content:flex-start;
    }

    .column-right {
        /* margin-right: 5em; */
        justify-content: start;
        align-items: start;
        padding-left: 2em;
        margin-right: 4em;
    }

    .skillset-title {
        text-transform: uppercase;
        font-size: 1.5rem;
        color: white;
        padding-top: 1em;
        padding-bottom: 1em;
    }


    .image-container {
        width: 20em;
        padding-bottom: 2em;
        
    }
    
    .headshot {
        width: 100%;
        border-radius: 0.5em;
    }
    
    .mobile-btn {
        display: none;
    }

    .desktop-btn {
        display:inline-flex;
        flex-direction: column;
    }
    
    
    .bio-paragraph-one, .bio-paragraph-two {
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: 2.5rem;
        color: var(--light-gray);
        width: 60em;

        text-align: unset;
    }
    
    .bio-paragraph-one {
        padding-bottom: 1em;
    }

    .bio-paragraph-two {
        padding-bottom: 2em;
    }


    .details {
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: 4.2em;

        align-items: start;
        text-align: left;
        font-size: 18px;
    }
    
    .name, .education, .employment {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
    }

    .name {
        padding-right: 4em;
    }
    
    .detail-title {
        color: #FF9AA5;
        font-weight: bold;
        text-transform: uppercase;
    }
    
    .myName, .school, .degree, .status {
        font-family: 'Poppins', sans-serif;
        color: var(--text-gray);
        font-weight: var(--fw-medium);
    }

    .employment {
        font-size: 18px;
    }

    .dropdown-container {
        display: none;
    }

    .desktop-dropdown {
        display: inline-flex;
        padding-top: 2em;
    }


    .computer-container{
        display: inline-flex;
        width: 100%;
    }

     
    .mobile-dropdown {
        display: none;
    }

    .dark-mode-container {
        position: absolute;
        bottom: 0;
        right: 0;
        margin-bottom: 2.1em;
        margin-right: 2em;
    }
}