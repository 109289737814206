.navigation {
    display: flex;
    justify-content: space-between;
    padding: 1.3em;
    margin-right: 9em;
    text-decoration: none;
}

.logo-link {
    display: inline-block;
    color: var(--pink);
    text-decoration: none;
    font-weight: var(--fw-bold);
    font-size: 1.125rem;
}

.logo-link:hover {
    -webkit-animation: jello-horizontal 0.9s both;
	    animation: jello-horizontal 0.9s both;
}

.links {
    display: flex;
    gap: 2.813em;
}

.links .nav-link, .contact-button {
    font-size: 1.25rem;
    text-decoration: none;
    color: var(--white);
    font-weight: var(--fw-semi-bold);
}

.contact-button {
    font-family: var(--body-font);
    background: none;
    border: none;
    cursor: pointer;
}

.links .nav-link:hover {
    color: var(--pink);
}

.contact-button:hover {
    color: var(--pink);
}


.links .active {
    color: var(--pink);
    font-weight: var(--fw-extrabold);
}


/* Mobile navigation */
.mobile-nav {
    display: none;
}

@-webkit-keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
              transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
              transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
              transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
              transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
              transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
  }
  @keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
              transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
              transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
              transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
              transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
              transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
  }



/* smaller screens and mobile phones */
@media only screen and (min-width: 320px) and (max-width:427px) {

    .desktop-nav {
        display: none;
    }

    .navigation {
        padding-top: 1em;
    }

    .logo-link {
        margin-top: 0.1em;
    }

    .mobile-nav {
        display:flex;
        flex-direction: row;

        padding-top: 0;
    }
}

/* larger mobile screens*/
@media only screen and (max-width: 768px) {

    .desktop-nav {
        display: none;
    }

    .navigation {
        padding-top: 1em;
    }

    .logo-link {
        padding-top: 0;
        margin: 0;
    }
}

/* small tablet screens portrait */
@media only screen and (min-width: 769px) and (max-width:1024px){
    .desktop-nav {
        display: none;
    }

}

