/* Default styles for the sidebar */
.sidebar {
  width: 250px;
  height: 100%;
  background-color: #FF9AA5;
  position: fixed;
  top: 0;
  right: -250px; /* Initially hidden on the right */
  transition: right 0.3s ease-in-out;
  z-index: 999;
}

/* Styles to show the sidebar when 'open' class is applied */
.open {
  right: 0; /* Move the sidebar to the visible position on the right */
}

.menu-button {
  display: none;
}

.menu {
  padding: 20px;
}

.menu ul {
  list-style: none;
  padding: 0;
}

.menu li {
  margin-bottom: 10px;
}

.link {
  color: #2E2E2E;
  text-decoration: none;
  font-size: 2.25rem;
}

/* Media query for mobile phones */
@media only screen and (max-width: 768px) { 

  .menu-button {
    display: block;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1000 !important; /* Ensure the menu button is on top of other content */
    animation: bounce 1s infinite; /* Apply bounce animation to the menu button */
  }


  /* Styles to open the sidebar when the 'open' class is applied on smaller screens */
  .open {
    right: 0; /* Move the sidebar to the visible position on the right */
  }
}


/* tablets */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  
  .sidebar {
    width: 400px;
    right: -400px;
    transition: right 0.3s ease-in-out;
  }

  .open {
    right: 0;
  }

  .menu-button {
    display: block;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1000 !important; /* Ensure the menu button is on top of other content */
    animation: bounce 1s infinite; /* Apply bounce animation to the menu button */
  }

  .link {
    font-size: 3rem;
  }
}