.contact-btn {
    display:  inline-block;
    color: var(--button-text);
    background-color: var(--pink);
    border-radius: 0.3125em;
    text-decoration: none;
    text-transform: uppercase;
    font-family: var(--body-font);
    font-weight: var(--fw-bold);
    font-size: 1.25rem;
    padding: 1em 2em;
    border: none;
    cursor: pointer;
    /* transition: transform 250ms; */
    transition: transform 600ms;

    &:hover {
        color: var(--medium-gray);
        background-color: var(--dark-gray);
        transform: translateY(-10px);
    }
}

/* values for light theme */
[data-theme="light"] {

}
