.container {
    display: grid; 
    width: 100vw;
    height: 100vh;
    overflow: auto;
    
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto; /* Adjusted this line */
    grid-template-areas:
        "header"
        "main"
        "footer";
}

.header-section {
    grid-area: header;
}

.main-content {
    grid-area: main;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title {
    font-size: 1.875rem;
    text-transform: uppercase;
    color: var(--white);
    margin-bottom: 2em;
    padding-bottom: 1em;
}

.project-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 2em;
}

.footer-section {
    grid-area: footer;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 2em;
    margin-right: 2em;
}

.swipe-instruction {
    display: flex;
    gap: 1em;
    color: white;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    margin-right: 8em;
    padding-top: 1em;
}

.swipe-arrow{
    width: 15px;
    height: 15px;
}

/* small mobile screens */
@media only screen and (min-width: 300px) and (max-width: 400px)  {

    
    .container {
        grid-template-rows: auto 1fr auto;
    }

    .project-grid {
        display: none;
    }
    .title {
        margin-top: -3em;
        margin-right: 120px;
        padding-bottom: 1em;
    }

    .mobile-carousel {
        padding: 0;
        margin: 0;
        overflow: auto;  /* Added this line */
        height: calc(100vh - (headerHeight + footerHeight)); 
    }

    .footer-section {
        display: none;
    }
    /* .footer-section {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin-bottom: 2.5em;
        margin-right: 7.5em;
    } */
    
}


/* large mobile screens and small tablets */
@media only screen and (min-width: 401px) and (max-width: 768px) {

    body, .container {
        overflow: hidden; /* Ensure that there is no horizontal overflow */
        height: auto;
    }
    
    .container {
        grid-template-rows: auto 1fr auto;
    }

    .project-grid {
        display: none;
    }

    .title {
        margin-right: 45px;
        margin-top: 0.5em;
    }

    .swipe-instruction {
        margin-right: 2em;
    }

    .footer-section {
        display: none;
    }
    /* .footer-section {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 3em;
    } */
}

/* ipad portrait */
@media only screen and (min-width: 769px) and (max-width: 1366px) {

    .project-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        gap: 2em;
        padding-bottom: 3em;
    }

    .title {
        margin-top: 2em;
        padding-bottom: 0; 
    }

    .mobile-carousel {
        display: none;
    }

    .footer-section {
        display: none;
    }

}

/* medium-large desktop screens */
@media only screen and (min-width: 1024px) {
    .mobile-carousel {
        display: none;
    }

    .swipe-instruction {
        display: none;
    }
}



