/* keyframes */
@keyframes bubbleOpen {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    85% {
      transform: scale(1.1);
      opacity: 1;
    }
    100% {
      transform: scale(1);
    }
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    animation: fadeIn 0.1s ease-out forwards;
}

.modal {
    
    background: var(--label-background);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 500px;
    z-index: 1001;

    animation: bubbleOpen 0.4s ease-out forwards;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}


.heading {
    margin: 0;
    color: var(--white);
    font-size: 2rem;
    text-transform: uppercase;
}

.closeButton {
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    color: #FF9AA5;
}

.modalContent {
    display: flex;
    flex-direction: column;
}

.modalContent label {
    margin-top: 10px;
    color: var(--white);
    font-weight: var(--fw-semi-bold);
}

/* .modalContent input, 
.modalContent textarea {
    padding: 10px;
    margin-top: 5px;
    border: 2px solid var(--border-color);
    border-radius: 5px;
} */

.modalContent input {
    padding: 10px;
    margin-top: 5px;
    border: 2px solid var(--border-color);
    border-radius: 5px;
    height: 45px;
    font-family: var(--socials-font);
    font-weight: var(--fw-medium);
    font-size: 1rem;
}

.modalContent textarea {
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 12px;
    border: 2px solid var(--border-color);
    border-radius: 5px;
    height: 120px;
    font-family: var(--socials-font);
    font-weight: var(--fw-medium);
    font-size: 1rem;
}


.submitButton {
    font-family: var(--body-font);
    color: var(--button-text);
    background-color: var(--pink);
    border-radius: 0.3125em;
    border:none;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: var(--fw-bold);
    font-size: 1.25rem;
    padding: 1em 2em;
    width: 11.5em;

    margin: 20px auto;

    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    /* transition: transform 250ms; */
    transition: transform 600ms;
    

    &:hover {
        color: var(--medium-gray);
        background-color: var(--dark-gray);
        transform: translateY(-10px);
    }
}



.email-link {
    color: #FF9AA5;
    font-size: 0.9rem;
    text-decoration: none;

    &:hover {
        color: var(--dark-gray);
    }
}

.socialLinks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.connect{
    padding-bottom: 1em;
    color: var(--text-gray);
}

.image-container {
    display: flex;
    gap: 0.5em;
    padding-bottom: 1em;
}
.linkedIn, .github {
    width: 45px;
    transition: transform 600ms;

    &:hover {
        transform: translateY(-10px);
    }
}

.email-direct {
    display: flex;
    justify-content: center;
    align-items: center;
}

.email-direct h3 {
    color: var(--text-gray);
}


.success-heading {
    color: var(--white);
    text-align: center;
    margin-left: 5.5em;
}

.success-message {
    color: var(--text-gray);
    text-align: center;
    font-weight: var(--fw-semi-bold);
    padding-bottom: 1em;
}
