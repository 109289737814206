.mobile-dropdown {
    width: 100%;
}

.tab {
    margin-bottom: 15px;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
}

.tab-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    cursor: pointer;
    background-color: var(--label-background);  
    padding: 10px;
    color: var(--text-gray);  
    border-radius: 5px;
    flex-shrink: 0;

    /* width: calc(100% - 20px); */
    max-width: 170px;
    /* max-width: 250px; */
    overflow: hidden;
}

.tab[data-tab="Hobbies"] .tab-header {
    display: flex;
    gap: 0.5em;
    padding-right: 1em;
}

.icon,
.arrow {
    width: 20px;
    margin-left: 10px;  
}

.content-container {
    width: 100%;
    overflow: hidden;
}

.tab-content {
    padding: 10px;
    border-radius: 5px;

    width: 100%;
    overflow: hidden;
}


.frontend-images, .backend-images, .cms-images {
    display: flex;
    gap: 0.5em;
    padding-bottom: 0.5em;
}

.title {
    color: var(--lighter-gray);
    padding-bottom: 0.5em;
    
}

.content-icon, .javascript-icon {
    width: 40px;
}

.dev-tools, .design-tools {
    color: var(--pink);
    font-weight: var(--fw-medium);
    padding-bottom: 1em;
}


/* ipad portrait */
@media only screen and (min-width: 769px) and (max-width: 1366px) {

    .tab-header {
        font-size: 20px;
        width: 250px;
    }
}
