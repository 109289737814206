footer {
    margin-left: 2.5em;
    padding-top: 2em;
    display: flex;
    justify-content: space-between;
}

.footer-links {
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 1.7em;
 
}

.column-left {
    display: flex;
    flex-direction: row;
    gap: 1em;
}

.profile-img {
    /* background-color: var(--white);
    border-radius: 50%;
    width: 70px;
    height: 70px;
    transition: transform 250ms;

    &:hover {
        transform: translateY(-10px);
    } */
    display: none;
}

.socials {
    display: flex;
    align-items: center;
    align-content: center;
    margin-top: 2.5em;
    gap: 2em;

}

.socials a {
    
    font-family: var(--socials-font);
    font-size: 16px;
    text-decoration: none;
    color: var(--white);

    &:hover {
        color: var(--pink);

    }
}


.linkedin-links {
    display: flex;
    align-items:center;
    gap: 0.65em;
}

.linkedin-icon {
    width: 30px;
    transition: transform 600ms;

    &:hover {
        transform: translateY(-10px);
    }

}

.share {
    width: 18px;
    height: 18px;
    margin-top: 1em;
    transition: transform 600ms;

    &:hover {
        transform: translateY(-10px);
    }
}


.link {
    margin-top: 0.5em;
    transition: transform 600ms;

    &:hover {
        transform: translateY(-10px);
    }
}

.github-links {
    display: flex;
    align-items: center;
    gap: 0.65em;
}
.github-icon {
    width: 30px;
    transition: transform 600ms;

    &:hover {
        transform: translateY(-10px);
    }
}

.column-right {
    margin-right: 2em;
    margin-top: 2.5em;
}

.light-mode-btn {
    cursor: pointer;
    width: 40px;
    transition: transform 600ms;

    &:hover {
        transform: translateY(-10px);
    }
}

.dark-mode-btn {
    width: 40px;
    transition: transform 600ms;

    &:hover {
        transform: translateY(-10px);
    }
}

.mobile-footer {
    display: none;
}

/* small mobile screens */
@media only screen and (min-width: 300px) and (max-width: 400px)  {
    .light-mode-btn {
        margin-left: -2em;
    }
}

/* portrait mobile screens */
@media only screen and (max-width: 768px){

    .column-left {
        display: none;
    }
    
    .column-right {
        display: none; 
    }

    footer {
        margin: 0;
    }

    .mobile-footer {
        display: flex;
        flex-direction: row;
        gap: 1em;
        align-items: center;
    }

    .mobile-social {
        display: flex;
        gap: 1em;
        justify-content: center;
        align-items: center;
    }

    .mobile-dark-mode {
        position: absolute;
        margin-left: 13em;
        margin-top: 0.5em;
    }

    .linkedin-mobile {
        width: 3.125em;
    }

    .github-mobile {
        width: 3.125em;
    }

    .light-mode-btn {
        transition: none;

        &:hover {
            transform:none;
        }
    }
}

/* tablets */
@media only screen and (min-width: 769px) and (max-width: 1024px){
    .column-left {
        display: none;
    }
    
    .column-right {
        display: none; 
    }

    footer {
        margin: 0;
    }

    .mobile-footer {
        display: flex;
        flex-direction: row;
        gap: 1em;
        align-items: center;
    }

    .mobile-social {
        display: flex;
        gap: 1em;
    }

    .mobile-dark-mode {
        position: absolute;
        margin-left: 13em;
        margin-top: 0.5em;
    }

    .linkedin-mobile {
        width: 4em;
    }

    .github-mobile {
        width: 4em;
    }
}