.main-container {
    width: 100%;
    /* height: 100%; */
    min-height: 90vh;
    display: grid;
    overflow:hidden;

    /* grid-template-columns: auto auto;
    grid-template-rows: auto;
    grid-template-areas:
        "main image"
        "footer footer"; */
    
    grid-template-columns: auto;
    grid-template-rows: 1fr auto;
    grid-template-areas:
        "main image"
        "footer footer"
}

.column-left {
    grid-area: main;
    margin-left: 5em;
}

.title {
    color: var(--white);
    text-transform: uppercase;
    font-weight: var(--fw-bold);
    font-size: 3.125rem;

    margin-top: 1.5em;
    margin-left: 0.3em;
    padding-bottom: 0.5em;

}

.vertical-line {
    width: 1px;
    background-color: var(--white);
    height: 3.7em;
    margin-top: 0.8em;
    margin-right: 1em;
    float: left;
}

.main-text {
    font-size: 1.563rem;
    letter-spacing: 40;
    font-weight: var(--fw-bold);
    color: var(--light-gray);
    line-height: 1.7em;
    padding-bottom: 2em;
}

.column-right {
    grid-area: image;
    width: 100%;
    margin-right: 20em;
}

.hero-image {
    width: 100%;
    margin-top: 4em; 
}

.footer-section {
    grid-area: footer;
    /* margin-top: 10em; */

}

/* mobile phones portrait */
@media only screen and (max-width: 768px){

    .main-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .column-right {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0;
    }

    .hero-image {
        padding-top: 1em;
        text-align: center;
        margin-top: 0;
        max-width: 70%;
    }

    .column-left {
        margin: 0;
        text-align: center;
        padding-bottom: 1em;
        
    }
    .title {
        font-size: 2rem;
        text-align: center;
        margin-top: 1em;
    }

    .main-text {
        font-size: 16px;
        line-height: 2em;
        padding-bottom: 2em;
    }

    .vertical-line {
        display: none;
    }

    .footer-section {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0;
        margin-bottom: 2em;
        padding: 0;
    }
}  

/* tablets landscape */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (orientation: landscape) {
    .main-container {
        width: 100%;
        /* height: 100%; */
        min-height: 80vh;
        display: grid;
        overflow:hidden;
    
        /* grid-template-columns: auto auto;
        grid-template-rows: auto;
        grid-template-areas:
            "main image"
            "footer footer"; */
        
        grid-template-columns: auto;
        grid-template-rows: 1fr auto;
        grid-template-areas:
            "main image"
            "footer footer"
    }
    
    .column-left {
        grid-area: main;
        margin-left: 5em;
    }
    
    .title {
        color: var(--white);
        text-transform: uppercase;
        font-weight: var(--fw-bold);
        font-size: 3.125rem;
    
        margin-top: 1.5em;
        margin-left: 0.3em;
        padding-bottom: 0.5em;
    
    }
    
    .vertical-line {
        width: 1px;
        background-color: var(--white);
        height: 3.7em;
        margin-top: 0.8em;
        margin-right: 1em;
        float: left;
    }
    
    .main-text {
        font-size: 1.563rem;
        letter-spacing: 40;
        font-weight: var(--fw-bold);
        color: var(--light-gray);
        line-height: 1.7em;
        padding-bottom: 2em;
    }
    
    .column-right {
        grid-area: image;
        width: 100%;
        margin-right: 20em;
    }
    
    .hero-image {
        width: 100%;
        margin-top: 4em; 
    }
    
    .footer-section {
        grid-area: footer;
        /* margin-top: 10em; */
    
    }
}

/* tablets portrait */
@media only screen and (min-width: 769px) and (max-width:1024px){
    .main-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .column-right {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0;
    }

    .hero-image {
        padding-top: 1em;
        text-align: center;
        margin-top: 0;
        max-width: 70%;
    }

    .column-left {
        margin: 0;
        text-align: center;
        padding-bottom: 1em;
        
    }
    .title {
        font-size: 2.5rem;
        text-align: center;
        margin-top: 1em;
    }

    .main-text {
        font-size: 20px;
        line-height: 2em;
        padding-bottom: 2em;
    }

    .vertical-line {
        display: none;
    }

    .footer-section {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0;
        margin-bottom: 2em;
        padding: 0;
    }
}