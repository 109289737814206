.form-container {
    background: var(--background);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content {
    display: flex;
    flex-direction: column;
}

.heading {
    text-transform: uppercase;
    color: var(--white);
    padding-top: 1em;
    padding-bottom: 1em;
}

.content label {
    font-size: 1.2rem;
    margin-top: 10px;
    color: var(--white);
    font-weight: var(--fw-semi-bold);
}

.content input {
    /* padding: 10px; */
    padding: 0.5em;
    margin-top: 5px;
    margin-bottom: 12px;
    height: 50px;
    border-radius: 5px;
    border: 2px solid var(--border-color);
    font-family: var(--socials-font);
    font-size: 1rem;
    font-weight: var(--fw-medium);
}

.content textarea {
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 2em;
    border-radius: 5px;
    border: 2px solid var(--border-color);
    height: 120px;

    font-family: var(--socials-font);
    font-weight: var(--fw-medium);
    font-size: 1rem;
}

.submitButton {
    font-family: var(--body-font);
    color: var(--button-text);
    background-color: var(--pink);
    border-radius: 0.3125em;
    border:none;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: var(--fw-bold);
    font-size: 1.25rem;
    padding: 1em 2em;
    width: 11.5em;

    margin: 20px auto;

    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: transform 600ms;
    

    &:hover {
        color: var(--medium-gray);
        background-color: var(--dark-gray);
        transform: translateY(-10px);
    }
}

.email-link {
    color: #FF9AA5;
    font-size: 0.9rem;
    text-decoration: none;

    &:hover {
        color: var(--dark-gray);
    }
}

.socialLinks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

}

.connect {
    padding-bottom: 1em;
    color: var(--text-gray);
} 

.image-container {
    display: flex;
    gap: 0.5em;
    padding-bottom: 1em;
}

.linkedIn, .github {
    width: 50px;
}

.email-direct {
    display: flex;
    justify-content: center;
    align-items: center;
}

.email-direct h3 {
    color: var(--text-gray);
    padding-bottom: 2em;
}

.thank-you-container {
    margin-top: 2em;
}


.success-heading {
    color: var(--white);
    text-align: center;
    padding-bottom: 2em;
}

.success-message {
    color: var(--text-gray);
    text-align: center;
    font-weight: var(--fw-semi-bold);
    padding-bottom: 1em;
}

.btn-link {
    text-decoration: none;
}


@media only screen and (min-width: 300px) and (max-width: 400px)  {

    .form-container {
        padding-left: 1em;
    }

    .content input {
        width: 320px;
    }

    .submitButton, .connect, .image-container, .email-direct {
        margin-right: 3.5em;
    }

    .content textarea {
        width: 320px;
    }


    .email-direct {
        text-align: center;
    }

}