.card-container {
    width: 350px;
    
    background-color: var(--card-background);
    border-radius: 5px;

    height: 500px;

}

.card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0.6em;
    
}

.card-image-container {
    overflow: hidden;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 350px;
}

.card-image {
    width: 100%;
    transition: transform 0.5s ease-in-out, opacity 0.4s ease-in-out;
    display: block;
}

.title {
    color: var(--background);
    padding-top: 0.5em;
}


.sub-title {
    color: var(--lighter-gray);
    padding-bottom: 0.5em;
}

.description {
    font-size: 14px;
    font-weight: var(--fw-semi-bold);
    color: var(--background);
    padding-bottom: 1em;
    width: 335px;
}

h2 {
    font-size: 18px;
}

h3 {
    font-size: 14px;
}

h4 {
    color: var(--lighter-gray);
}

.tags-container {
    display: flex;
    flex-direction: row;
    
}

span {
    font-size: 14px;
    color: var(--lighter-gray);
    font-weight: var(--fw-semi-bold);
    padding-bottom: 0.5em;
}

.tags ul {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
}

.tags li {
    font-size: 12px;
    font-weight: var(--fw-semi-bold);
    color: #2E2E2E;
    background-color: var(--lighter-gray);
    border-radius: 10px;
    list-style-type: none;
    padding: 0.3em 0.7em;
    
    &:hover {
        background-color: var(--pink);
    }
}

.btn-container {
    display: flex;
    gap: 1em;
    padding-top: 1em;
    padding-bottom: 1em;
    align-items: center;
    justify-content: center;
    margin-right: 0.6em;
}

.view-live-btn, .view-source-btn {
    color: var(--background);
    background-color: var(--pink);
    border-radius: 0.3125em;
    text-decoration: none;
    font-weight: var(--fw-bold);
    font-size: 14px;
    padding: 1em 1em;
    transition: all 0.2s ease-in-out;
}


/* values for light theme */
.card-container-light {
    background-color: white;
    border: 1px solid #f2f2f2;     
}

.title-light {
    color: #2E2E2E; 
    
}

.sub-title-light {
    color: #8B8B8B;
}

.description-light {
    color: #5A5A5A;
}

.span-light {
    color: #8B8B8B;
}


@media(hover) {
    .card-image:hover {
        transform: scale(1.6);
    }

    .view-live-btn:hover, .view-source-btn:hover {
        color: var(--medium-gray);
        background-color: var(--dark-gray);

    }
    
}

@media only screen and (max-width: 400px){
    .card-container {
        width: 300px;
    }

    .title {
        font-size: 16px;
    }
    
    .sub-title {
        font-size: 12px;
    }
    
    .description {
        font-size: 12px;
        font-weight: var(--fw-bold);
        color: var(--background);
        padding-bottom: 1em;
        width: 300px;
    } 

    span {
        font-size: 12px;
    }

    .tags li {
        font-size: 10px;
    }

    .view-live-btn, .view-source-btn {
        font-size: 12px;
    }

        

}
    