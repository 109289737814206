.content-icon {
    width: 40px;
}

.skills-container {
    display: flex;
    flex-direction: column;
}

.front-end, .back-end, .CMS {
    display: flex;
    gap: 0.5em;
}

.front-end, .back-end {
    padding-bottom: 1em;
}


.tooltip {
    visibility: hidden;

    background-color: var(--card-background);
    color: #fff;
    font-size: 12px;
    text-align: center;
    padding: 5px 10px;
    border-radius: 5px;
    white-space: nowrap;

    position: absolute;
    z-index: 1;
    bottom: 100%;    /* It's positioning the tooltip above the icon */
    left: 50%;        /* Centering the tooltip */
    transform: translateX(-50%);   /* Adjusting the tooltip position to its true center */
    opacity: 0;
    transition: opacity 0.3s;
    
}


.tooltip::after {
    content: "";
    font-size: 12px;
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.icon-container:hover .tooltip {
    visibility: visible;
    opacity: 1;
}
