.carouselContainer {
  position: relative;
  width: calc(450px + 40px); /* 450px for slides, 40px for each arrow */
  overflow: hidden;
  margin: 0 auto;
}

.carouselWrapper {
  display: flex;
  transition: transform 0.4s ease-in-out;
  width: 450px;  /* Width of the slides only */
  margin-left: 40px; /* Margin for the left arrow */
}

.carouselSlide {
  width: calc(100% / 3);
  transition: transform 0.4s ease-in-out;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.carouselButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 2;
  width: 40px;
  height: 40px;
  opacity: 0.7;
  transition: opacity 0.2s;
  padding: 0;
  margin: 0;
}

.carouselButton:hover {
  opacity: 1;
}

#prevSlide {
  left: 0px;
  background: url('../images/left.png') no-repeat center center;
}

#nextSlide {
  right: 60px;
  background: url('../images/right.png') no-repeat center center;
}


@media only screen and (max-width: 400px){

  .carouselWrapper {
    margin-left: 32px; /* Margin for the left arrow */
  }

  #prevSlide {
    left: 0px;
  }
/* 
  #nextSlide {
    right: 0px;
  } */

  #nextSlide {
    margin-right: 65px;
  }
}