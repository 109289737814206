.drop-down {
    width: 400px;
    height: 45px; /* 45-200 */
    border-radius: 5px;
    overflow: hidden;
    /* background: var(--card-background); */
    /* background-color: var(--background);  */
    /* box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075),
    0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075),
    0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075);
    background: var(--card-background); */
    display: flex;
    flex-direction: column;
}


.dropdown-nav {
    /* background: var(--card-background); */
    padding: 5px 5px 0;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    /* border-bottom: 1px solid #535353; */
    border-bottom: 1px solid var(--label-background);
    height: 44px;
}

.tab-container {
    display: flex;
    flex-direction: row;
    width: 130px;


    /* justify-content: flex-start;
    align-items: flex-end; */
    gap: 0.5em;
    padding: 0.4em;
}

.tab-label {
    display: flex;
    gap: 0.2em;
}

.tab-list {
    display: flex;
    flex-direction: row;
    list-style-type: none;
}
  
.tab-list li {
    position: relative;
    cursor: pointer; 
}

.label-text {
    font-family: 'Poppins', sans-serif;
    color: var(--white);
    /* color: var(--background); */
    font-weight: var(--fw-medium)
}
  
.underline {
    width: 100%;
    left: 0;
    transform: none;
}

.title {
    color: var(--text-gray);
    padding-bottom: 0.5em;
}

.skills-content, .tools-content, .hobbies-content {
    display: flex;
    flex-direction: column;
    margin-left: 1em;
    padding-left: 0.4em;
}

.front-end {
    display: flex;
    padding-top: 0.5em;
}

.frontend-images {
    display: flex;
    gap: 0.5em;
    justify-content: center;
    align-items: center;
    padding-bottom: 1em;
}

.backend-images {
    display: flex;  
    padding-top: 0.5em;
    padding-bottom: 1em;
}

.cms-images {
    display: flex;
    gap: 0.5em;
    padding-top: 0.5em;

}

.dev-tools, .design-tools {
    color: var(--pink);
    font-weight: var(--fw-medium);
    padding-bottom: 1em;
}

.icon, .arrow {
    width: 20px;
}

.content-icon {
    width: 40px;
}

.javascript-icon {
    width: 45px;
}



.down-arrow {
    margin-left: 5px; 
    height: auto;
}


.main {
    margin-top: 20px;
}


.selected {
    background-color: var(--label-background); 
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
  
.underline {
    height: 1px;
    background-color: #FF9AA5;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.tooltip {
    visibility: hidden;

    background-color: var(--card-background);
    color: #fff;
    font-size: 12px;
    text-align: center;
    padding: 5px 10px;
    border-radius: 5px;
    white-space: nowrap;

    position: absolute;
    z-index: 1;
    bottom: 100%;    /* It's positioning the tooltip above the icon */
    left: 50%;        /* Centering the tooltip */
    transform: translateX(-50%);   /* Adjusting the tooltip position to its true center */
    opacity: 0;
    transition: opacity 0.3s;
    
}

.tooltip::after {
    content: "";
    font-size: 12px;
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.icon-container:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

